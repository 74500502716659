import React, { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { FaFonticonsFi, FaQuestionCircle, FaUsers, FaVenusMars } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { RiTeamLine } from "react-icons/ri";
import { TbClubs } from "react-icons/tb";
import { BiRestaurant } from "react-icons/bi";
import Sidebar from "../components/AdminSidebar";
import useStore from "../store/store";
import { getAllMatches, getAllPortals, getUsers } from "../Firebase/apis";
import {
  MdContactSupport,
  MdDashboard,
  MdOnlinePrediction,
  MdOutlineCardMembership,
  MdOutlineFamilyRestroom,
  MdOutlineMailOutline,
} from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";

const VenuePortals = lazy(() => import("../pages/Admin/VenuePortals").then(module => ({ default: module.VenuePortals })));
const Countries = lazy(() => import("../pages/Admin/Countries").then(module => ({ default: module.Countries })));
const CountryForm = lazy(() => import("../pages/Admin/Countries/CountryForm").then(module => ({ default: module.CountryForm })));
const Clubs = lazy(() => import("../pages/Admin/Clubs").then(module => ({ default: module.Clubs })));
const ClubForm = lazy(() => import("../pages/Admin/Clubs/ClubForm").then(module => ({ default: module.ClubForm })));
const Matches = lazy(() => import("../pages/Admin/Matches").then(module => ({ default: module.Matches })));
const MatchForm = lazy(() => import("../pages/Admin/Matches/MatchForm").then(module => ({ default: module.MatchForm })));
const Teams = lazy(() => import("../pages/Admin/Teams").then(module => ({ default: module.Teams })));
const TeamForm = lazy(() => import("../pages/Admin/Teams/TeamForm").then(module => ({ default: module.TeamForm })));
const Restaurants = lazy(() => import("../pages/Admin/Restaurants").then(module => ({ default: module.Restaurants })));
const AddRestaurant = lazy(() => import("../pages/Admin/Restaurants/RestaurantForm").then(module => ({ default: module.AddRestaurant })));
const Users = lazy(() => import("../pages/Admin/Users").then(module => ({ default: module.Users })));
const VenuesSuggestions = lazy(() => import("../pages/Admin/VenueSuggestions").then(module => ({ default: module.VenuesSuggestions })));
const PortalForm = lazy(() => import("../pages/Admin/VenuePortals/PortalForm").then(module => ({ default: module.PortalForm })));
const UserForm = lazy(() => import("../pages/Admin/Users/userForm").then(module => ({ default: module.UserForm })));
const Support = lazy(() => import("../pages/Admin/support").then(module => ({ default: module.Support })));
const Settings = lazy(() => import("../pages/Admin/Settings"));
const Predictions = lazy(() => import("../pages/Admin/predictions").then(module => ({ default: module.Predictions })));
const Ultras = lazy(() => import("../pages/Admin/Ultras"));
const FAQS = lazy(() => import("../pages/Admin/FAQS").then(module => ({ default: module.FAQS })));
const VenueStats = lazy(() => import("../pages/Admin/VenuePortals/VenueStats"));
const MembershipRequests = lazy(() => import("../pages/Admin/SubscriptionRequests").then(module => ({ default: module.MembershipRequests })));
const Dashboard = lazy(() => import("../pages/Admin/Dashboard").then(module => ({ default: module.Dashboard })));
const Login = lazy(() => import("../pages/Admin/Login").then(module => ({ default: module.Login })));
const Notifications = lazy(() => import("../pages/Admin/Notifications"));


const SIDEBAR_LINKS = [
  {
    path: "/admin/dashboard",
    title: "Dashboard",
    Icon: <MdDashboard />,
  },
  {
    path: "/admin/venueportals",
    title: "Venue Portals",
    Icon: <MdOutlineFamilyRestroom />,
  },
  {
    path: "/admin/venusuggestions",
    title: "Venue Suggestions",
    Icon: <FaVenusMars />,
  },
  {
    path: "/admin/ultras",
    title: "Ultras",
    Icon: <FaUsers />,
  },
  {
    path: "/admin/users",
    title: "Users",
    Icon: <FaUsers />,
  },
  {
    path: "/admin/leagues",
    title: "Leagues",
    Icon: <TbClubs />,
  },
  {
    path: "/admin/teams",
    title: "Teams",
    Icon: <RiTeamLine />,
  },
  {
    path: "/admin/matches",
    title: "Matches",
    Icon: <MdOutlineMailOutline />,
  },
  {
    path: "/admin/restaurants",
    title: "Restaurants",
    Icon: <BiRestaurant />,
  },
  {
    path: "/admin/support",
    title: "Support",
    Icon: <MdContactSupport />,
  },
  {
    path: "/admin/predictions",
    title: "Predictions",
    Icon: <MdOnlinePrediction />,
  },
  {
    path: "/admin/faqs",
    title: "FAQS",
    Icon: <FaQuestionCircle />,
  },
  {
    path: "/admin/notifications",
    title: "Notifications",
    Icon: <IoIosNotifications />,
  },
  {
    path: "/admin/membershipreqs",
    title: "Membership Requests",
    Icon: <MdOutlineCardMembership />,
  },
  {
    path: "/admin/settings",
    title: "Settings",
    Icon: <IoSettingsSharp />,
  },
];

const AdminRoutes = () => {
  const admin = sessionStorage.getItem("fanspot-admin");
  const { setData } = useStore((state) => state);
  const [isAdminPortal, setIsmAdminPortal] = useState(false);

  const logoutHandler = () => {
    const c = window.confirm("Are you sure to logout?");
    if (!c) return;
    sessionStorage.removeItem("fanspot-admin");
    window.location.replace("/admin");
  };

  const getData = async () => {
    let portals = await getAllPortals();
    portals = portals.filter((item) => !item.isDeleted);
    let users = await getUsers();
    users = users.filter((item) => !item.isDeleted);
    const matches = await getAllMatches();
    setData(portals, users, matches);
  };
  useEffect(() => {
    if (window.location.pathname.includes("/admin")) {
      getData();
      setIsmAdminPortal(true);
    }
  }, []);

  return !admin ? (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/admin" element={<Login />} />
      </Routes>
    </Suspense>
  ) : (
    <Sidebar data={SIDEBAR_LINKS} logoutHandler={logoutHandler} isAdmin={isAdminPortal}>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="/admin" element={<Navigate to={"/admin/dashboard"} />} />
          <Route path="/admin/dashboard" element={<Dashboard />} />
          <Route path="/admin/countries" element={<Countries />} />
          <Route path="/admin/countries/add" element={<CountryForm />} />
          <Route path="/admin/teams" element={<Teams />} />
          <Route path="/admin/teams/add" element={<TeamForm />} />
          <Route path="/admin/matches" element={<Matches />} />
          <Route path="/admin/matches/add" element={<MatchForm />} />
          <Route path="/admin/venueportals/add" element={<PortalForm />} />
          <Route path="/admin/venueportals/stats" element={<VenueStats />} />
          <Route path="/admin/leagues" element={<Clubs />} />
          <Route path="/admin/users" element={<Users />} />
          <Route path="/admin/users/add" element={<UserForm />} />
          <Route path="/admin/venusuggestions" element={<VenuesSuggestions />} />
          <Route path="/admin/clubs/add" element={<ClubForm />} />
          <Route path="/admin/restaurants" element={<Restaurants />} />
          <Route path="/admin/restaurants/add" element={<AddRestaurant />} />
          <Route path="/admin/venueportals" element={<VenuePortals />} />
          <Route path="/admin/support" element={<Support />} />
          <Route path="/admin/faqs" element={<FAQS />} />
          <Route path="/admin/notifications" element={<Notifications />} />
          <Route path="/admin/predictions" element={<Predictions />} />
          <Route path="/admin/membershipreqs" element={<MembershipRequests />} />
          <Route path="/admin/ultras" element={<Ultras />} />
          <Route path="/admin/settings" element={<Settings />} />
        </Routes>
      </Suspense>
    </Sidebar>
  );
};

export default AdminRoutes;

import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import NotiSound from "../assets/notiSound.mp3";
const subscription_basic = "Basic - Free";
const subscription_standard = "Standard - SAR 1,530";
const subscription_premium = "Premium - SAR 2,400";

export const TIMINGS = [
  "01 AM",
  "02 AM",
  "03 AM",
  "04 AM",
  "05 AM",
  "06 AM",
  "07 AM",
  "08 AM",
  "09 AM",
  "10 AM",
  "11 AM",
  "12 PM",
  "01 PM",
  "02 PM",
  "03 PM",
  "04 PM",
  "05 PM",
  "06 PM",
  "07 PM",
  "08 PM",
  "09 PM",
  "10 PM",
  "11 PM",
  "12 AM",
];
export const TIMINGS_DATA = [
  { name: "01 AM" },
  { name: "02 AM" },
  { name: "03 AM" },
  { name: "04 AM" },
  { name: "05 AM" },
  { name: "06 AM" },
  { name: "07 AM" },
  { name: "08 AM" },
  { name: "09 AM" },
  { name: "10 AM" },
  { name: "11 AM" },
  { name: "12 PM" },
  { name: "01 PM" },
  { name: "02 PM" },
  { name: "03 PM" },
  { name: "04 PM" },
  { name: "05 PM" },
  { name: "06 PM" },
  { name: "07 PM" },
  { name: "08 PM" },
  { name: "09 PM" },
  { name: "10 PM" },
  { name: "11 PM" },
  { name: "12 AM" },
];

export const dayNames = [
  { eng: "Sunday", ar: "الاحد" },
  { eng: "Monday", ar: "الاثنين" },
  { eng: "Tuesday", ar: "الثلاثاء" },
  { eng: "Wednesday", ar: "الاربعاء" },
  { eng: "Thursday", ar: "الخميس" },
  { eng: "Friday", ar: "الجمعه" },
  { eng: "Saturday", ar: "السبت" },
];
export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const getWeekDates = () => {
  const currentDate = new Date();
  const pastThreeDate = new Date(new Date().setDate(new Date().getDate() - 3));
  const pastTwoDate = new Date(new Date().setDate(new Date().getDate() - 2));
  const pastOneDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const futureThreeDate = new Date(
    new Date().setDate(new Date().getDate() + 3)
  );
  const futureTwoDate = new Date(new Date().setDate(new Date().getDate() + 2));
  const futureOneDate = new Date(new Date().setDate(new Date().getDate() + 1));
  return [
    { date: pastThreeDate.getDate(), day: days[pastThreeDate.getDay()] },
    { date: pastTwoDate.getDate(), day: days[pastTwoDate.getDay()] },
    { date: pastOneDate.getDate(), day: days[pastOneDate.getDay()] },
    { date: currentDate.getDate(), day: days[currentDate.getDay()] },
    { date: futureOneDate.getDate(), day: days[futureOneDate.getDay()] },
    { date: futureTwoDate.getDate(), day: days[futureTwoDate.getDay()] },
    { date: futureThreeDate.getDate(), day: days[futureThreeDate.getDay()] },
  ];
};
export const getMonthDates = () => {
  const currentDate = new Date();
  const futureTwentyNineDate = new Date(
    new Date().setDate(new Date().getDate() + 29)
  );
  const futureTwentyEightDate = new Date(
    new Date().setDate(new Date().getDate() + 28)
  );
  const futureTwentySevenDate = new Date(
    new Date().setDate(new Date().getDate() + 27)
  );
  const futureTwentysixDate = new Date(
    new Date().setDate(new Date().getDate() + 26)
  );
  const futureTwentyfiveDate = new Date(
    new Date().setDate(new Date().getDate() + 25)
  );
  const futureTwentfourDate = new Date(
    new Date().setDate(new Date().getDate() + 24)
  );
  const futureTwentythreeDate = new Date(
    new Date().setDate(new Date().getDate() + 23)
  );
  const futureTwentytwoDate = new Date(
    new Date().setDate(new Date().getDate() + 22)
  );
  const futureTwentyoneDate = new Date(
    new Date().setDate(new Date().getDate() + 21)
  );
  const futureTwentyDate = new Date(
    new Date().setDate(new Date().getDate() + 20)
  );
  const futureNineteenDate = new Date(
    new Date().setDate(new Date().getDate() + 19)
  );
  const futureEighteenDate = new Date(
    new Date().setDate(new Date().getDate() + 18)
  );
  const futureSeventeenDate = new Date(
    new Date().setDate(new Date().getDate() + 17)
  );
  const futureSixteenDate = new Date(
    new Date().setDate(new Date().getDate() + 16)
  );
  const futureFifteenDate = new Date(
    new Date().setDate(new Date().getDate() + 15)
  );
  const futurefourteenDate = new Date(
    new Date().setDate(new Date().getDate() + 14)
  );
  const futureThriteenDate = new Date(
    new Date().setDate(new Date().getDate() + 13)
  );
  const futureTwelveDate = new Date(
    new Date().setDate(new Date().getDate() + 12)
  );
  const futureElevenDate = new Date(
    new Date().setDate(new Date().getDate() + 11)
  );
  const futureTenDate = new Date(new Date().setDate(new Date().getDate() + 10));
  const futureNineDate = new Date(new Date().setDate(new Date().getDate() + 9));
  const futureEightDate = new Date(
    new Date().setDate(new Date().getDate() + 8)
  );
  const futureSevenDate = new Date(
    new Date().setDate(new Date().getDate() + 7)
  );
  const futureSixDate = new Date(new Date().setDate(new Date().getDate() + 6));
  const futureFiveDate = new Date(new Date().setDate(new Date().getDate() + 5));
  const futureFourDate = new Date(new Date().setDate(new Date().getDate() + 4));
  const futureThreeDate = new Date(
    new Date().setDate(new Date().getDate() + 3)
  );
  const futureTwoDate = new Date(new Date().setDate(new Date().getDate() + 2));
  const futureOneDate = new Date(new Date().setDate(new Date().getDate() + 1));
  return [
    {
      date: currentDate.getDate(),
      fullDate: currentDate,
      day: dayNames[currentDate.getDay()],
    },
    {
      date: futureOneDate.getDate(),
      fullDate: futureOneDate,
      day: dayNames[futureOneDate.getDay()],
    },
    {
      date: futureTwoDate.getDate(),
      fullDate: futureTwoDate,
      day: dayNames[futureTwoDate.getDay()],
    },
    {
      date: futureThreeDate.getDate(),
      fullDate: futureThreeDate,
      day: dayNames[futureThreeDate.getDay()],
    },
    {
      date: futureFourDate.getDate(),
      fullDate: futureFourDate,
      day: dayNames[futureFourDate.getDay()],
    },
    {
      fullDate: futureFiveDate,
      date: futureFiveDate.getDate(),
      day: dayNames[futureFiveDate.getDay()],
    },
    {
      fullDate: futureSixDate,
      date: futureSixDate.getDate(),
      day: dayNames[futureSixDate.getDay()],
    },
    {
      fullDate: futureSevenDate,
      date: futureSevenDate.getDate(),
      day: dayNames[futureSevenDate.getDay()],
    },
    {
      fullDate: futureEightDate,
      date: futureEightDate.getDate(),
      day: dayNames[futureEightDate.getDay()],
    },
    {
      fullDate: futureNineDate,
      date: futureNineDate.getDate(),
      day: dayNames[futureNineDate.getDay()],
    },
    {
      fullDate: futureTenDate,
      date: futureTenDate.getDate(),
      day: dayNames[futureTenDate.getDay()],
    },
    {
      fullDate: futureElevenDate,
      date: futureElevenDate.getDate(),
      day: dayNames[futureElevenDate.getDay()],
    },
    {
      fullDate: futureTwelveDate,
      date: futureTwelveDate.getDate(),
      day: dayNames[futureTwelveDate.getDay()],
    },
    {
      date: futureThriteenDate.getDate(),
      fullDate: futureThriteenDate,
      day: dayNames[futureThriteenDate.getDay()],
    },
    {
      date: futurefourteenDate.getDate(),
      day: dayNames[futurefourteenDate.getDay()],
      fullDate: futurefourteenDate,
    },
    {
      fullDate: futureFifteenDate,
      date: futureFifteenDate.getDate(),
      day: dayNames[futureFifteenDate.getDay()],
    },
    {
      fullDate: futureSixteenDate,
      date: futureSixteenDate.getDate(),
      day: dayNames[futureSixteenDate.getDay()],
    },
    {
      fullDate: futureSeventeenDate,
      date: futureSeventeenDate.getDate(),
      day: dayNames[futureThreeDate.getDay()],
    },
    {
      fullDate: futureEighteenDate,
      date: futureEighteenDate.getDate(),
      day: dayNames[futureEighteenDate.getDay()],
    },
    {
      fullDate: futureNineteenDate,
      date: futureNineteenDate.getDate(),
      day: dayNames[futureNineteenDate.getDay()],
    },
    {
      fullDate: futureTwentyDate,
      date: futureTwentyDate.getDate(),
      day: dayNames[futureTwentyDate.getDay()],
    },
    {
      fullDate: futureTwentyoneDate,
      date: futureTwentyoneDate.getDate(),
      day: dayNames[futureTwentyoneDate.getDay()],
    },
    {
      fullDate: futureTwentytwoDate,
      date: futureTwentytwoDate.getDate(),
      day: dayNames[futureTwentytwoDate.getDay()],
    },
    {
      fullDate: futureTwentythreeDate,
      date: futureTwentythreeDate.getDate(),
      day: dayNames[futureTwentythreeDate.getDay()],
    },
    {
      fullDate: futureTwentfourDate,
      date: futureTwentfourDate.getDate(),
      day: dayNames[futureTwentfourDate.getDay()],
    },
    {
      fullDate: futureTwentyfiveDate,
      date: futureTwentyfiveDate.getDate(),
      day: dayNames[futureTwentyfiveDate.getDay()],
    },
    {
      fullDate: futureTwentysixDate,
      date: futureTwentysixDate.getDate(),
      day: dayNames[futureTwentysixDate.getDay()],
    },
    {
      fullDate: futureTwentySevenDate,
      date: futureTwentySevenDate.getDate(),
      day: dayNames[futureTwentySevenDate.getDay()],
    },
    {
      fullDate: futureTwentyEightDate,
      date: futureTwentyEightDate.getDate(),
      day: dayNames[futureTwentyEightDate.getDay()],
    },
    {
      fullDate: futureTwentyNineDate,
      date: futureTwentyNineDate.getDate(),
      day: dayNames[futureTwentyNineDate.getDay()],
    },
  ];
};

export const CHANNELS = [
  {
    label: "Bein Sports",
    value: "Bein Sports",
  },
  {
    label: "Abu Dhabi Sports",
    value: "Abu Dhabi Sports",
  },
  {
    label: "SSC Sports",
    value: "SSC Sports",
  },
];
export const SUBSCRIPTIONS = [
  { id: 1, name: subscription_basic },
  { id: 2, name: subscription_standard },
  { id: 3, name: subscription_premium },
];

export const getSubscriptions = (locale) => {
  return [
    {
      id: 1,
      name: locale.subscription_basic
        ? locale.subscription_basic
        : subscription_basic,
    },
    {
      id: 2,
      name: locale.subscription_standard
        ? locale.subscription_standard
        : subscription_standard,
    },
    {
      id: 3,
      name: locale.subscription_premium
        ? locale.subscription_premium
        : subscription_premium,
    },
  ];
};
export const getContactTypes = (locale) => {
  return [
    {
      id: 1,
      name: locale.portal_subscription_request_option_inquiry
        ? locale.portal_subscription_request_option_inquiry
        : "Inquiry",
    },
    {
      id: 2,
      name: locale.portal_subscription_request_option_request
        ? locale.portal_subscription_request_option_request
        : "Request",
    },
    {
      id: 3,
      name: locale.portal_subscription_request_option_complain
        ? locale.portal_subscription_request_option_complain
        : "Complain",
    },
    {
      id: 3,
      name: locale.portal_subscription_request_option_suggest
        ? locale.portal_subscription_request_option_suggest
        : "Suggest",
    },
  ];
};

export const MEMBERSHIP_REQUESTS = [
  { id: 1, name: "Trial" },
  { id: 2, name: "Renew" },
  { id: 3, name: "Upgrade" },
];
export const PAYMENTS_TYPES = [
  { id: 2, name: "Monthly" },
  { id: 3, name: "Yearly" },
];
export const CONTACT_REQUESTS = [
  { id: 2, name: "Inquiry" },
  { id: 3, name: "Request" },
  { id: 4, name: "Complain" },
  { id: 5, name: "Suggest" },
];

export const COUNTRIES = [{ id: 1, name: "Saudi Arabia" }];

export const readRegionsAndCities = () => {
  // const results = [];
  // for (let i = 0; i < res.length; i++) {
  //   const ele = res[i];
  //   if (i > 1) {
  //     const index = results.findIndex((item) => item.province.eng === ele[0]);
  //     if (index >= 0) {
  //       const cCopy = [...results[index].cities];
  //       cCopy.push({
  //         eng: ele[2],
  //         ar: ele[3],
  //       });
  //       results[index].cities = cCopy;
  //     } else {
  //       const obj = {
  //         province: {
  //           eng: ele[0],
  //           ar: ele[1],
  //         },
  //         cities: [
  //           {
  //             eng: ele[2],
  //             ar: ele[3],
  //           },
  //         ],
  //       };
  //       results.push(obj);
  //     }
  //   }
  // }
};

const user = JSON.parse(sessionStorage.getItem("fanspot-portal"));

export const userStatus =
  user &&
    user.subscriptions &&
    user.subscriptions.find((item) => item !== SUBSCRIPTIONS[0].name)
    ? true
    : false;

export const userStatusforStandard =
  user &&
    user.subscriptions &&
    user.subscriptions.find((item) => item !== SUBSCRIPTIONS[1].name)
    ? true
    : false;
export const userStatusforPremium =
  user &&
    user.subscriptions &&
    user.subscriptions.find((item) => item === SUBSCRIPTIONS[2].name)
    ? true
    : false;

export const WORKING_HOURS = [
  {
    day: {
      eng: "sunday",
      ar: "الاحد",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "monday",
      ar: "الاثنين",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "tuesday",
      ar: "الثلاثاء",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "wednesday",
      ar: "الاربعاء",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "thursday",
      ar: "الخميس",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "friday",
      ar: "الجمعه",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
  {
    day: {
      eng: "saturday",
      ar: "السبت",
    },
    startTime: "09PM",
    status: false,
    endTime: "11PM",
  },
];

export const foodsVarities = [
  {
    label: "Fast Food",
    value: "Fast Food",
    arrabic: "مأكولات سريعة",
  },
  {
    label: "Sandwiches",
    value: "Sandwiches",
    arrabic: "شطائر",
  },
  {
    label: "Arabic",
    value: "Arabic",
    arrabic: "عربي",
  },
  {
    label: "Desserts",
    value: "Desserts",
    arrabic: "حلويات",
  },
  {
    label: "American",
    value: "American",
    arrabic: "أمريكي",
  },
  {
    label: "Beverages",
    value: "Beverages",
    arrabic: "مشروبات",
  },
  {
    label: "Coffee",
    value: "Coffee",
    arrabic: "قهوة",
  },
  {
    label: "International",
    value: "International",
    arrabic: "عالمي",
  },
  {
    label: "Shawarma",
    value: "Shawarma",
    arrabic: "شاورما",
  },
  {
    label: "Italian",
    value: "Italian",
    arrabic: "إيطالي",
  },
  {
    label: "Bakery",
    value: "Bakery",
    arrabic: "مخبوزات",
  },
  {
    label: "Grill",
    value: "Grill",
    arrabic: "مشاوي",
  },
  {
    label: "Seafood",
    value: "Seafood",
    arrabic: "بحري",
  },
  {
    label: "Juices",
    value: "Juices",
    arrabic: "عصيرات",
  },
  {
    label: "Asian",
    value: "Asian",
    arrabic: "آسيوي",
  },
  {
    label: "Healthy Food",
    value: "Healthy Food",
    arrabic: "مأكولات صحية",
  },
  {
    label: "Indian",
    value: "Indian",
    arrabic: "هندي",
  },
  {
    label: "Mexican",
    value: "Mexican",
    arrabic: "مكسيكي",
  },
  {
    label: "Burgers",
    value: "Burgers",
    arrabic: "برجر",
  },
  {
    label: "Saudi",
    value: "Saudi",
    arrabic: "سعودي",
  },
  {
    label: "Sushi",
    value: "Sushi",
    arrabic: "سوشي",
  },
  {
    label: "Pasta",
    value: "Pasta",
    arrabic: "باستا",
  },
  {
    label: "Japanese",
    value: "Japanese",
    arrabic: "ياباني",
  },
  {
    label: "Vegetarian",
    value: "Vegetarian",
    arrabic: "نباتي",
  },
  {
    label: "Lebanese",
    value: "Lebanese",
    arrabic: "لبناني",
  },
  {
    label: "Salads",
    value: "Salads",
    arrabic: "سلطات",
  },
  {
    label: "Falafel",
    value: "Falafel",
    arrabic: "فلافل",
  },
  {
    label: "Breakfast",
    value: "Breakfast",
    arrabic: "فطور",
  },
  {
    label: "Noodles",
    value: "Noodles",
    arrabic: "نودلز",
  },
  {
    label: "Middle eastern",
    value: "Middle eastern",
    arrabic: "طعام شرق أوسطي",
  },
  {
    label: "Lunch",
    value: "Lunch",
    arrabic: "غذاء",
  },
];

export function downloadExcel(data, sheetName) {
  const wb = XLSX.utils.book_new();
  /* create a worksheet for data */
  const wsData = XLSX.utils.json_to_sheet(data);
  /* Add the worksheet to the workbook */
  XLSX.utils.book_append_sheet(wb, wsData, sheetName);
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data1 = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data1, sheetName + ".xlsx");
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

export function get_current_month_dates() {
  const cDate = new Date();
  const year = cDate.getFullYear();
  const month = cDate.getMonth();
  let date = new Date(year, month, 1);
  let dates = [];
  while (date.getMonth() === month) {
    const nDate = new Date(date);
    dates.push({
      date: nDate.getDate(),
      fullDate: nDate,
      day: days[nDate.getDay()],
    });
    date.setDate(date.getDate() + 1);
  }
  return dates;
}

export const getCurrentDate = (date) => {
  let d = new Date();
  if (date) {
    d = new Date(date);
  }
  return (
    d.getFullYear() +
    "-" +
    (d.getMonth() <= 8 ? "0" + (d.getMonth() + 1) : +(d.getMonth() + 1)) +
    "-" +
    (d.getDate() <= 9 ? "0" + d.getDate() : d.getDate())
  );
};
export const checkPastDate = (date) => {
  const givenDate = new Date(date);
  const currentDate = new Date();
  givenDate.setHours(0, 0, 0, 0);
  currentDate.setHours(0, 0, 0, 0);
  if (givenDate < currentDate) {
    return true;
  }

  return false;
};

export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const FILTER_NOTIFICATIONS_CATEGORIES = [
  { name: "Region" },
  { name: "City" },
  { name: "Clubs followed" },
  { name: "National teams followed" },
  { name: "Leagues followed" },
  { name: "Most active users" },
  { name: "Recently inactive users" },
  { name: "Users with upcoming bookings" },
  { name: "Users who often book venues or Ultras events" },
  { name: "Customers interested in offers and discounts" },
  { name: "Customers who participated in score prediction games" },
  { name: "Customers who have recently visited a venue" },
  { name: "Customers who have recently rated a venue" },
  { name: "Users who often book for major events" },
];

export const playSound = () => {
  let audio = new Audio(NotiSound);
  audio.play();
};
